import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';
import { fetchVisitorInfo } from './utils/api';
import { dispatchEvent } from './utils/events';
import { isPlainObject } from './utils/helpers';
import { extractDecorationParams, extractAttributionParams } from './utils/url';

import type { McfxConfigOptions, SessionState } from './declarations';

/**
 * Session Tracking
 * @param {Required<McfxConfigOptions>} MCFX Configuration Options
 */
export class Session {
  private _values: Map<string, any>;
  private visitorSessionLength: number;
  private cookieDomain: string;
  private useSecureCookies: boolean;
  private agentUrl: string;

  constructor(configuration: Required<McfxConfigOptions>) {
    this.visitorSessionLength = configuration.visitorSessionLength;
    this.cookieDomain = configuration.cookieDomain;
    this.useSecureCookies = configuration.useSecureCookies;
    this._values = new Map();
    this.agentUrl = configuration.agentUrl;

    this.createSession();
  }

  get validTTL(): boolean {
    return new Date().getTime() < this._values.get('ttl');
  }

  get cookiedUID(): string | undefined {
    const _fx = Cookies.get('__fx');
    const fx_uuid = Cookies.get('fx_uuid');

    if (_fx !== 'undefined') {
      return _fx;
    }

    if (fx_uuid !== 'undefined') {
      return fx_uuid;
    }

    return undefined;
  }

  createSession(): SessionState {
    const currentTime = new Date().getTime();
    const local = window.localStorage.getItem('__fx');
    const store = local ? JSON.parse(local) : {};

    const session: SessionState = {
      sid: uuid(),
      pg: Cookies.get('mcfxPGID'),
      r: document.referrer,
      // overide defaults with restorable session
      ...(currentTime < store?.ttl ? store : {}),

      uid: this.cookiedUID || store.uid || uuid(),
      ttl: new Date().getTime() + 1000 * 60 * this.visitorSessionLength,
      gaId: Cookies.get('_ga'),
      ...extractDecorationParams(),
    };

    if (typeof session.s === 'undefined' || typeof session.m === 'undefined') {
      Object.entries(extractAttributionParams()).forEach(([key, value]) => {
        session[key] = value;
      });

      // We perform this async because we dont need to wait
      // do to data used during collection
      fetchVisitorInfo(session.r, this.agentUrl).then((visitorInfo) => {
        this.set({
          ...extractAttributionParams(visitorInfo ?? {}),
          s: visitorInfo?.source,
          m: visitorInfo?.medium,
          st: visitorInfo?.term,
          l: visitorInfo?.location,
          ip: visitorInfo?.ip,
        });
        this.cacheToLocalStorage();

        // GCP does not properly handle the IP address of Reverse-Proxy need to hit directly
        // via a second request to get the correct Location data
        if (visitorInfo?.location.userIP === '34.27.204.111') {
          fetchVisitorInfo(session.r, 'https://t.marketingcloudfx.com').then((locationInfo) => {
            this.set({
              l: locationInfo?.location,
            });
            this.cacheToLocalStorage();
          });
        }

        dispatchEvent('session:visitorInfo', this.get());
      });
    }

    this.set(session);
    this.cacheToLocalStorage();

    if (window.gtag) {
      window.gtag('set', 'user_properties', {
        mcfxVisitorId: this.get('uid'),
        mcfxSession: this.get('sid'),
        gaId: this.get('gaId'),
      });
    }

    dispatchEvent('session:created', session);
    return session;
  }

  /**
   * cache the session to users local storage
   * @private
   * @param {object} values to cacheToLocalStorage
   */
  private cacheToLocalStorage() {
    window.localStorage.setItem('__fx', JSON.stringify(this.get()));
    Cookies.set('__fx', this.get('uid'), {
      path: '/',
      domain: this.cookieDomain,
      secure: this.useSecureCookies,
      expires: 365 * 10,
    });

    dispatchEvent('session:saved', this.get());
  }

  /**
   * Gets the value of one or more properties in the session.
   * @param prop The name of the property to get, or an array of property names to get.
   * @returns The value of the property (if `prop` is a string), an object containing key-value pairs of all properties (if `prop` is not provided), or an object containing key-value pairs of the specified properties (if `prop` is an array).
   */
  get(prop?: string | string[]): any | Record<string, any> {
    if (!prop || (Array.isArray(prop) && !prop.length)) {
      return Object.fromEntries(this._values.entries());
    }

    if (Array.isArray(prop)) {
      return prop.reduce((out, p) => {
        out[p] = this._values.get(p);
        return out;
      }, {});
    }

    return this._values.get(prop);
  }

  /**
   * Sets one or more properties in the session.
   * @param prop The name of the property to set, or an object containing key-value pairs to set.
   * @param _value The value to set the property to (if `prop` is a string).
   * @returns void
   */
  set(prop: string | Record<string, any>, _value?: any): void {
    if (isPlainObject(prop)) {
      Object.entries(prop).forEach(([key, value]) => {
        this._values.set(key, value);
      });
    } else {
      this._values.set(prop as string, _value);
    }
    this.cacheToLocalStorage();
  }
}

export default Session;
