/**
 * Checks if the given object is a function.
 *
 * @param {any} obj - The object to check.
 * @returns {boolean} - True if the object is a function, false otherwise.
 */
export function isFunction(obj: any): boolean {
  return !!(obj && obj.constructor && obj.call && obj.apply);
}

/**
 * Checks if the given value is an object.
 *
 * @param {any} value - The value to check.
 * @returns {boolean} - True if the value is an object, false otherwise.
 */
export function isObject(o: any): boolean {
  return Object.prototype.toString.call(o) === '[object Object]';
}

/**
 * Checks if the given value is defined and not null.
 *
 * @param {any} value - The value to check.
 * @returns {boolean} - True if the value is defined and not null, false otherwise.
 */
export function isDefined(value: any): boolean {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }

  return true;
}

/**
 * Checks if the given value is defined and not empty
 *
 * @param {any} value - The value to check.
 * @returns {boolean} - True if the value is defined and not empty, false otherwise.
 */
export function isDefinedOrEmpty(value: any): boolean {
  if (!isDefined(value) || value === "" || (Array.isArray(value) && value.length > 0)) {
    return false;
  }

  return true;
}

/**
 * Checks if the given value is a plain object.
 *
 * @param {any} value - The value to check.
 * @returns {boolean} - True if the value is a plain object, false otherwise.
 */
export function isPlainObject(value: any): boolean {
  if (!isObject(value)) {
    return false;
  }

  // if is null
  if (value === null) {
    return false;
  }

  // If has modified constructor
  const ctor = value.constructor;
  const prot = ctor.prototype;

  if (ctor === undefined) {
    return true;
  }

  // If has modified prototype
  if (!isObject(prot)) {
    return false;
  }

  // If constructor does not have an Object-specific method
  if (!prot.isPrototypeOf) {
    return false;
  }

  // Most likely a plain Object
  return true;
}

/**
 * Checks if the current window is inside an iframe.
 *
 * @returns {boolean} - True if the current window is inside an iframe, false otherwise.
 */
export function isIframed(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

/**
 * Checks if the current window is inside an iframe.
 *
 * @returns {boolean} - True if the current window is inside an iframe, false otherwise.
 */
export function isBrowserTrackable(): boolean {
  const dntProperties = ['doNotTrack', 'msDoNotTrack'];

  for (let i = 0, l = dntProperties.length; i < l; i++) {
    // eslint-disable-next-line eqeqeq
    if (navigator[dntProperties[i]] && navigator[dntProperties[i]] == '1') {
      return false;
    }
  }

  return true;
}

/**
 * Determine if the value is a credit card using a basic Luhn Validation.
 *
 * @param {string|number} value - The value to check.
 * @returns {boolean} - True if the value is a valid credit card number, false otherwise.
 *
 * @example
 * isCreditCard('4111 1111 1111 1111'); // true
 * isCreditCard('4111-1111-1111-1111'); // true
 * isCreditCard('4111 1111 1111 1112'); // false
 *
 */
export function isCreditCard(value: string): boolean {
  if (/[^0-9-\s]+/.test(value)) {
    return false;
  }
  if (String(value).length < 12 || String(value).length > 19) {
    return false;
  }

  let nCheck = 0,
    nDigit = 0,
    bEven = false;

  value = value.replace(/\D/g, '');

  for (let n = value.length - 1; n >= 0; n--) {
    const cDigit = value.charAt(n);
    nDigit = parseInt(cDigit, 10);
    if (bEven) {
      if ((nDigit *= 2) > 9) {
        nDigit -= 9;
      }
    }
    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
}

/**
 * Protect specific form field names from capturing
 * their real values and convert to *s
 *
 * @param {object} values
 * @param {Element<Form>} form
 * @returns {object} the sanitized/protected object
 */
export function protectSensitiveData(values: any, form: HTMLFormElement) {
  return Object.entries(values).reduce((d, [key, val]) => {
    let out = val;
    if (
      (form && `${form.elements[key]?.type}`.toLowerCase() === 'password') ||
      /password/.test(key) ||
      /^pin[_-]?(num(ber)?)?/.test(key) ||
      /credit[_-]?card/.test(key) ||
      /card[_-]?number/.test(key) ||
      /cc[_-]?num(ber)?/.test(key) ||
      /cvv/.test(key) ||
      isCreditCard(`${val}`) ||
      /^\d{3}-\d{2}-\d{4}$/.test(`${val}`) ||
      /social[_-]?(security)?[_-]?(num(ber)?)?/.test(key)
    ) {
      out = ''.padEnd(`${out}`.length, '*');
    }

    d[key] = out;
    return d;
  }, {});
}
