import { isPlainObject } from './utils/helpers';
import { beacon } from './utils/request';

import Session from './session';
import { Visits } from './visits';
import { Forms } from './forms';
import { Reviews } from './reviews';
import { Personalization } from './personalization';
import { parseUrl, parseUrlParams } from './utils/url';
import { isDefined } from './utils/helpers';
import { fetchConfig } from './utils/api';
import { dispatchEvent } from './utils/events';
import './utils/history';

import type { McfxConfigOptions, McfxModules } from './declarations';

const ModuleMap = {
  visits: Visits,
  view: Visits,
  forms: Forms,
  reviews: Reviews,
  pfx: Personalization,
};

export class Tracker {
  initializedAt: number;
  configuration: Required<McfxConfigOptions>;
  session: Session;
  modules: Partial<McfxModules>;

  constructor(_options: McfxConfigOptions | string | number, initializedAt: number) {
    this.configuration = {
      formSessionLength: 10,
      visitorSessionLength: 30,
      trackInputs: true,
      useSecureCookies: true,
      urlComparedBy: 'href',
      onFormCapture: (form) => form,
      onViewCallback: (data) => data,
      filterFormInputs: (formData, _form) => formData,
      modules: ['view', 'forms', 'reviews'],
      cookieDomain: parseUrl()?.cookieDomain ?? window.location.hostname,
      agentUrl: `https://t.marketingcloudfx.com`,
      ...((isPlainObject(_options) ? _options : { siteId: _options }) as McfxConfigOptions),
    };
    this.initializedAt = initializedAt;

    // disable reviews if Nutshell
    if (`${this.configuration.siteId}`.toLowerCase().indexOf('ns-') === 0) {
      this.configuration.modules = this.configuration.modules.filter(
        (mod) => !['reviews'].includes(mod as string)
      );
    }

    this.session = new Session(this.configuration);
    this.modules = {};
    this.enableModule(this.configuration.modules);
    this.loadConfig();
    dispatchEvent('loaded', this);
  }

  /**
   * @deprecated
   */
  get options(): McfxConfigOptions {
    return this.configuration;
  }
  /**
   * @deprecated
   */
  get visitor(): Session {
    return this.session;
  }

  /**
   * Send event information to MCFX
   * @param  {...any} args
   * @returns
   */
  async send(...args) {
    let dataToPost;
    const attrib = {
      o: window.location.origin,
      u: window.location.href,
      te: new Date().getTime(),
      ...this.session.get(),
      a: this.configuration.siteId,
    };

    if (isPlainObject(args[0])) {
      // allows prop name of hitType or type
      if (args[0].hitType) {
        args[0].type = args[0].hitType;
        delete args[0].hitType;
      }

      dataToPost = {
        type: 'event', // event | form
        category: null,
        action: null,
        label: null,
        value: null,
        ...args[0],
        attrib,
      };
    } else {
      dataToPost = {
        type: args[0] || 'event', // event | form
        category: args[1] || null,
        action: args[2] || null,
        label: args[3] || null,
        value: args[4] || null,
        attrib,
      };
    }

    beacon(`${this.configuration.agentUrl}/event`, dataToPost);
    dispatchEvent(`${dataToPost.type}:collect`, dataToPost);
    dispatchEvent(`collect`, dataToPost);
  }

  /**
   * Manual method to capture the current state of a form element
   * and send to MCFX for parsing
   * @param {HTMLNode} form
   */
  capture(form) {
    this.modules.forms?.capture(form);
  }

  /**
   * Set configuration properties for tracking
   * @param {*} prop
   * @param {*} value
   * @returns
   */
  configure(prop, value = null) {
    if (Array.isArray(prop)) {
      prop.forEach((k, i) => {
        this.configuration[k] = Array.isArray(value) && value[i] ? value[i] : value;
      });
      return;
    }

    if (isPlainObject(prop)) {
      Object.keys(prop).forEach((k) => {
        this.configuration[k] = prop[k];
      });
      return;
    }
    this.configuration[prop] = value;
  }

  /**
   * Set information about the visitor
   * @param  {...any} args
   */
  set(key: string | Record<string, any>, value?: any) {
    this.session.set(key, value);
  }

  /**
   * get information about the visitor/session
   * @param  {...any} args
   */
  get(key?: string | string[]) {
    return this.session.get(key);
  }

  /**
   * enable specific module(s)
   * @param {string|array} mods
   */
  enableModule(mods: keyof McfxModules | (keyof McfxModules)[] = []) {
    if (!Array.isArray(mods)) {
      mods = [mods];
    }
    mods.forEach((m) => {
      if (ModuleMap[m]) {
        this.modules[m] = new ModuleMap[m](this);
      }
    });
  }

  /**
   * disable specific module(s)
   * @param {string|array} mods
   */
  disableModule(mods: keyof McfxModules | (keyof McfxModules)[] = []): void {
    if (!Array.isArray(mods)) {
      mods = [mods];
    }
    mods.forEach((m) => {
      if (this.modules[m]) {
        this.modules[m]?.stop();
        delete this.modules[m];
      }
    });
  }

  /**
   * determine if a module is enabled
   * @param {string} mod
   * @returns {boolean}
   */
  isModuleEnabled(mod: string): boolean {
    return !!this.modules[mod];
  }

  /**
   * Decorates links on the page with session data.
   *
   * @param urls - The URLs to decorate.
   */
  decorate(urls: string | string[]) {
    if (!urls) {
      return;
    }
    if (!Array.isArray(urls)) {
      urls = [urls];
    }

    const links = document.querySelectorAll('a');
    const { r, ttl, ...sess } = this.session.get();

    links.forEach((link) => {
      const parsed = parseUrl(link.href);

      if (!parsed) {
        return;
      }

      const params = parseUrlParams(parsed.search);

      (urls as string[]).forEach((url) => {
        if (link.href.indexOf(url) !== -1) {
          const newParams = new URLSearchParams({
            ...params,
            ...Object.entries(sess).reduce((accum, [k, v]) => {
              if (k === 'l' || k === 'ip') {
                return accum;
              }
              if (isDefined(v)) {
                accum[`fx_${k}`] = v;
              }
              return accum;
            }, {}),
          }).toString();

          link.href = `${parsed.origin}${parsed.pathname}?${newParams}${parsed.hash}`;
        }
      });
    });
  }

  /**
   * Asynchronously fetch settings from MCFX and re-apply once loaded
   */
  async loadConfig() {
    const config = await fetchConfig(this.configuration.siteId, this.configuration.agentUrl).catch(
      (_err) => {}
    );
    if (!config) {
      dispatchEvent('configured', this);
      return;
    }

    const { modules = [], ...restConfig } = config;
    const removedModules = this.configuration?.modules?.filter(
      (enabledModule) => !modules.includes(enabledModule)
    );

    this.configure(restConfig);
    modules.forEach((mod) => {
      if (!this.configuration?.modules.includes(mod)) {
        this.enableModule(mod);
      }
    });

    this.disableModule(removedModules);
    dispatchEvent('configured', this);
  }
}

declare module './declarations' {
  type MCFXTracker = Tracker;
}
