import { request } from './request';
import { parseUrlParams } from './url';
import type { VisitorInfoResponse, McfxConfigOptions } from '../declarations';

/**
 * Fetch the config for the given site through a Cloud Function
 * and format it for the tracking script.
 * Note that the CF already caches the data.
 *
 * @param {number|string} siteId
 * @returns {Promise<Partial<McfxConfigOptions> | null} Object config settings of the site
 */
export async function fetchConfig(
  siteId: number | string,
  trackingDomain: string
): Promise<Partial<McfxConfigOptions> | null> {
  const url = `${trackingDomain}/config?siteId=${siteId}`;
  const req = await request(url).catch((_err) => {
    // no-op
  });

  if (!req) {
    return null;
  }

  const siteConfig = JSON.parse(req.response).data;
  if (!Object.keys(siteConfig).length) {
    return null;
  }

  const {
    visitTracking,
    leadTracking,
    visitorRecording,
    reviewIntegration,
    personalization,
    ...otherConfig
  } = siteConfig ?? {};
  const modules = [
    visitTracking ? 'view' : null,
    leadTracking ? 'forms' : null,
    reviewIntegration ? 'reviews' : null,
    personalization ? 'pfx' : null,
  ].filter((i) => i !== null);

  return {
    ...otherConfig,
    ...(modules.length ? { modules } : {}),
  };
}

/**
 * Return information about the visitor including attribution data
 * based on originating referrer.
 *
 * @param {string} referrer
 * @returns { Promise<VisitorInfoResponse | null>} Visitor Info Response
 */
export async function fetchVisitorInfo(
  referrer: string,
  trackingDomain: string
): Promise<VisitorInfoResponse | null> {
  try {
    const req = await request({
      type: 'POST',
      url: `${trackingDomain}/visitor`,
      data: JSON.stringify({
        referrer,
        ...parseUrlParams(),
      }),
    });

    return JSON.parse(req.response);
  } catch (err) {
    return null;
  }
}

export async function postPfxVisitor({
  siteId,
  visitorId,
  previewBlockId,
  ip,
}: {
  siteId: string | number;
  visitorId?: string;
  previewBlockId?: string;
  ip: string;
}): Promise<VisitorInfoResponse | null> {
  try {
    if (!previewBlockId && !visitorId) {
      return null;
    }

    const req = await request({
      type: 'POST',
      url: 'https://t.marketingcloudfx.com/pfx/match-visitor',
      data: {
        siteId,
        ...(visitorId ? { visitorId } : null),
        ...(previewBlockId ? { previewBlockId } : null),
        ip,
      },
    });

    return JSON.parse(req.response);
  } catch (err) {
    return null;
  }
}
