import { request } from './utils/request';
import { Module } from './module';
import { dispatchEvent } from './utils/events';
import type { MCFXTracker } from './declarations';
export class Reviews extends Module {
  el: string;
  reviews: any[];
  ready: boolean;
  retried: number;
  constructor(tracker: MCFXTracker) {
    super(tracker);
    this.fetch();
  }

  async fetch() {
    const req = await request(
      `https://cdn.leadmanagerfx.com/reviews/${this.tracker.configuration.siteId}/`
    ).catch((_err) => {});

    if (!req) {
      return;
    }
    const response = JSON.parse(req.response);

    this.el = response.selector;
    this.reviews = response.reviews;
    this.ready = true;

    if (this.el && this.reviews?.length) {
      this.render();
    }
  }

  render() {
    const $el = document.getElementById(this.el);

    if (!$el) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      if (this.retried < 2) {
        setTimeout(function () {
          that.retried++;
          that.render();
        }, 2000);
      }
      return;
    }

    $el.innerHTML = `
    <div class="mcfx-reviews">
      ${this.reviews.map(this.renderReview).join('')}
    </div>
    `;
    dispatchEvent('reviews:rendered', this.reviews);
  }

  renderReview(review) {
    let html = `
    <div class="mcfx-review" itemprop="review" itemscope itemtype="http://schema.org/Review">
      <span class="mcfx-review-author" itemprop="author">${review.author_name}</span>
      <span class="mcfx-review-date" itemprop="datePublished">${review.date}</span>
      <div itemprop="reviewRating" itemscope itemtype="http://schema.org/Rating">
        <meta itemprop="worstRating" content = "1"/>
        <span class="mcfx-review-number-rating">
          <span class="mcfx-review-rating" itemprop="ratingValue">${review.rating}</span>/
          <span class="mcfx-review-max" itemprop="bestRating">5</span> stars
        </span>
        <span class="mcfx-review-stars">
    `;

    for (let i = 1; i <= 5; i++) {
      if (review.rating >= i) {
        html += '<span class="mcfx-star mcfx-star-active"></span>';
      }
    }

    html += `
        </span>
      </div>
      <span class="mcfx-review-description" itemprop="reviewBody">${review.text}</span>
    </div>`;

    return html;
  }
}

// Add this service to the service type index
declare module './declarations' {
  interface McfxModules {
    ['reviews']: Reviews;
  }
}
