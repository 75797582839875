import { Module } from './module';
import { dispatchEvent } from './utils/events';
import type { MCFXTracker, PersonalizationKeys } from './declarations';
import { isObject, isPlainObject, isDefinedOrEmpty } from './utils/helpers';
import { postPfxVisitor } from './utils/api';

type Audience = {
  audienceId: string;
};

type Blocks = {
  active: boolean;
  audienceId: string;
  blockId: string;
  content: string;
  priority: number;
  targetId: string;
};

type Target = {
  audienceIds: string[];
  blocks: Blocks[];
  selector: string;
  targetId: string;
  urls: string[];
};

type PersonalizationObject = {
  audiences: Audience[];
  googleLocation: object;
  ip: string;
  siteId: number | string;
  targets: Target[];
  visitorId: string;
};

export class Personalization extends Module {
  ready: boolean;
  ssePath: string;
  visitorId: string;
  sseConnection: any;
  pfxAgentUrl: string;
  pfx: Array<PersonalizationKeys>;
  ip: string;
  matched: boolean;
  retryCount: number = 0;
  siteId: string | number;
  pfxObject: PersonalizationObject | null;
  previewId: string | null;
  contentSwapEndedAt: number | null;
  contentSwapStartedAt: number | null;
  sseConnectionOpenedAt: number | null;
  sseConnectionClosedAt: number | null;

  constructor(tracker: MCFXTracker) {
    super(tracker);
    this.visitorId = tracker.session.get('uid');
    this.ip = tracker.session.get('ip');
    this.siteId = tracker.configuration.siteId;
    this.pfxAgentUrl = `${tracker.configuration.agentUrl}/pfx`;
    this.pfx = [];
    this.tracker.session.set('pfx', this.pfx); // init in session
    this.matched = false;
    this.initMatching();
    this.start();
  }

  start = () => {
    this.previewOrReplace();
  };

  stop = () => {
    this.ready = false;
    this.retryCount = 0;
    this.previewId = null;
    this.pfxObject = null;
    this.sseConnection.close();
  };

  initMatching = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let docRef = `VIS_${this.visitorId}`;
    if (isDefinedOrEmpty(urlParams.get('previewBlockId'))) {
      this.previewId = urlParams.get('previewBlockId');
      docRef = `PRE_${this.previewId}`;
    }
    this.ssePath = `${this.pfxAgentUrl}/sse?sid=${this.siteId}_${docRef}`;

    postPfxVisitor({
      siteId: this.siteId,
      ...(this.previewId ? { previewBlockId: this.previewId } : { visitorId: this.visitorId }),
      ip: this.ip,
    });
  };

  previewOrReplace = (): void => {
    this.createEventSource();
  };

  /**
   * Sometimes when the Cloud-Run maxes nodes is reached
   * GCP does a request capture and tries to free up some space
   * which throws a bunch of sse errors in the pixel, try catch
   * here is to allow us wait as the service re-assigns a handler
   *
   * https://webpagefx.mangoapps.com/msc/MTY4MjMxNl8xMjkwMzY3Ng
   */
  createEventSource = (): void => {
    try {
      if (isObject(this.sseConnection)) {
        this.sseConnection.close();
      }

      this.sseConnection = new EventSource(this.ssePath);
      this.sseConnection.onerror = this.onErrorHandler;
      this.sseConnection.onopen = this.onConnectionOpen;
      this.sseConnection.onmessage = this.onMessageHandler;
    } catch (error) {
      dispatchEvent('pfx:sse-server-error', {
        error,
        visitorId: this.visitorId,
        siteId: this.siteId,
        ssePath: this.ssePath,
      });
      this.onRetryConnection();
    }
  };

  onConnectionOpen = (): void => {
    this.ready = true;
    this.sseConnectionOpenedAt = new Date().getTime();
  };

  onMessageHandler = (event: { data: string }): void => {
    const data = JSON.parse(event.data);

    if (data.event === 'snapshot') {
      if (isPlainObject(data.data) && !Array.isArray(data.data)) {
        this.pfxObject = data.data;
      } else {
        /**
         * Do nothing as data pattern is unknown.
         * required to refrain PFX from hoarding the
         * client site with infinite error logs
         **/
        dispatchEvent('pfx:bad-content-block', {
          data,
          visitorId: this.visitorId,
          siteId: this.siteId,
        });
        return;
      }

      this.processBlocks();
    }

    if (data.event === 'warning' && data.retry === true) {
      this.onDisconnect();
    }

    if (data.event === 'error') {
      dispatchEvent('pfx:sse-server-error', {
        data,
        visitorId: this.visitorId,
        siteId: this.siteId,
      });
    }
  };

  onErrorHandler = (event: { type: string; target: object }): void => {
    try {
      this.onRetryConnection();
    } catch (e: any) {
      // dispatch event and ignore
      if (this.retryCount > 3) {
        dispatchEvent('pfx:sse-failing', { event, visitorId: this.visitorId, siteId: this.siteId });
      }
      return;
    }
  };

  onDisconnect = (): void => {
    this.sseConnectionClosedAt = new Date().getTime();

    dispatchEvent('pfx:sse-connection-duration', {
      startedAt: this.sseConnectionOpenedAt,
      closedAt: this.sseConnectionClosedAt,
      diff: this.sseConnectionClosedAt - this.sseConnectionOpenedAt,
    });

    this.stop();
  };

  onRetryConnection = (): void => {
    this.onDisconnect();
    setTimeout(() => {
      this.retryCount++;
      this.start();
    }, 10000);
  };

  processBlocks = (): void => {
    if (!isDefinedOrEmpty(this.pfxObject)) {
      return;
    }

    this.contentSwapStartedAt = new Date().getTime();

    this.matched = false;
    for (let i = 0; i < this.pfxObject.targets.length; i++) {
      const target: Target = this.pfxObject.targets[i];
      if (target) {
        if (isDefinedOrEmpty(target.blocks)) {
          return;
        }

        this.updateDOMcontent(target);
      }
    }

    this.contentSwapEndedAt = new Date().getTime();

    if (this.matched) {
      this.tracker.session.set('pfx', this.pfx);
    }

    dispatchEvent('pfx:content-swap-time', {
      startedAt: this.contentSwapStartedAt,
      endedAt: this.contentSwapEndedAt,
      diff: this.contentSwapEndedAt - this.contentSwapStartedAt,
    });
  };

  updateDOMcontent = (target: Target): void => {
    const topBlock = target.blocks[0];
    const els = document.querySelectorAll(`${target.selector}`);

    if (this.previewId) {
      els.forEach((el) => {
        el.innerHTML = topBlock.content;
      });
      // since this is a preview, we don't want to track the pfx
      dispatchEvent('pfx:replaced-content-block-preview', {
        siteId: this.siteId,
        visitorId: this.visitorId,
        target,
        url: window.location.toString(),
      });
      return;
    }

    /**
     * We begin with a default of 'true' so all respective content-blocks
     * are replace for all pages instead of scoping changes to specific URLs
     */
    let urlMatched: boolean = !!els.length;
    const urlPath = `${window.location.origin}${window.location.pathname}`;

    if (!isDefinedOrEmpty(target.urls)) {
      urlMatched = target.urls?.some((url) => urlPath === url) ?? true;
    }

    if (urlMatched && els.length && topBlock.content) {
      els.forEach((el) => {
        el.innerHTML = topBlock.content;
      });
      this.matched = true;
      this.pfx.push({
        a: topBlock.audienceId,
        b: topBlock.blockId,
        t: target.targetId,
      });
      dispatchEvent('pfx:replaced-content-block', {
        siteId: this.siteId,
        visitorId: this.visitorId,
        target,
        url: window.location.toString(),
      });
    }
  };
}

// Add this service to the service type index
declare module './declarations' {
  interface McfxModules {
    ['pfx']: Personalization;
  }
}
