import { isPlainObject } from './helpers';
// https://xgwang.me/posts/you-may-not-know-beacon/#it-may-throw-error%2C-be-sure-to-catch
const windowBeacon = navigator.sendBeacon.bind(navigator);

export type RequestOptions = {
  url: string;
  type: string;
  data: any;
  dataType: string;
  async: boolean;
  timeout: number;
  headers?: Record<string, string>;
};

export function request(
  urlOrOptions: string | Partial<RequestOptions>,
  _options?: Partial<RequestOptions>
): Promise<XMLHttpRequest> {
  return new Promise((resolve, reject) => {
    let params: RequestOptions;
    const req = new XMLHttpRequest();

    const defaults = {
      url: '',
      type: 'GET',
      data: '',
      dataType: 'json',
      async: true,
      timeout: 3000,
      headers: {},
    };

    if (_options) {
      params = { ...defaults, url: urlOrOptions as string, ..._options };
    } else {
      params = {
        ...defaults,
        ...(_options || {}),
        ...((isPlainObject(urlOrOptions)
          ? urlOrOptions
          : { url: urlOrOptions as string }) as RequestOptions),
      };
    }
    if (!params.type) {
      reject('You must provide a request type');
    }

    params.type = params.type.toUpperCase();
    params.url = params.url.replace(
      /(^\w+:|^)\/\//,
      'https:' === document.location.protocol ? 'https://' : 'http://'
    );

    req.open(params.type, params.url);

    if (params.dataType === 'json') {
      if (typeof params.data !== 'string') {
        params.data = JSON.stringify(params.data);
      }
      req.setRequestHeader('Content-type', 'application/json');
    }

    if (params.type === 'POST' && params.dataType !== 'json') {
      req.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    }

    if (params.headers) {
      Object.entries(params.headers).forEach(([h, v]) => req.setRequestHeader(h, v));
    }

    req.onload = function () {
      if (req.status >= 400) {
        reject(req.responseText);
      } else {
        resolve(req);
      }
    };

    req.onerror = function () {
      reject(req.responseText);
    };

    if (params.data) {
      req.send(params.data);
    } else {
      req.send();
    }
  });
}

export function beacon(url: string, data: any) {
  if (!navigator.sendBeacon) {
    request({
      url,
      data,
      type: 'POST',
    }).catch((_err) => {});
    return;
  }
  try {
    const queued = windowBeacon(
      url.replace(
        /(^\w+:|^)\/\//,
        'https:' === document.location.protocol ? 'https://' : 'http://'
      ),
      typeof data !== 'string' ? JSON.stringify(data) : data
    );
    if (!queued) {
      throw new Error('Failed to queue beacon');
    }
  } catch (err) {
    request({
      url,
      data,
      type: 'POST',
    }).catch((_err) => {});
  }
}
